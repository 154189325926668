<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
    <div class="container relative">
        <div class="grid grid-cols-12">
            <div class="col-span-12">
                <div class="py-[60px] px-0">
                    <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div class="lg:col-span-4 md:col-span-12">
                            <a routerLink="#" class="text-[22px] focus:outline-none">
                                <source srcset="assets/images/logos/AimenshellEngLogBlanco.webp" type="image/webp">
                                <source srcset="assets/images/logos/AimenshellEngLogBlanco.png" type="image/png">
                                <img src="assets/images/logos/AimenshellEngLogBlanco.webp" class="block" alt="logo aimen shell toolkit">
                            </a>
                            <p class="mt-6 text-gray-300">Encouraging Asset Administration Shells AAS.</p>
                            <ul class="list-none mt-6">
                                <li class="inline"><a href="https://www.linkedin.com/company/aimenct/" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-linkedin" title="Linkedin"></i></a></li>
                                <li class="inline"><a href="https://x.com/aimenct" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-twitter align-middle" title="twitter"></i></a></li>
                                <li class="inline"><a href="https://www.facebook.com/aimenct" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                                <li class="inline"><a href="https://www.instagram.com/aimen_ct/" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
                                <li class="inline"><a href="https://www.youtube.com/c/AimenEs" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-youtube align-middle" title="youtube"></i></a></li>

                                <!-- <li class="inline"><a href="mailto:support@aimen.es" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-sky-500 dark:hover:border-sky-500 hover:bg-sky-500 dark:hover:bg-sky-500"><i class="uil uil-envelope align-middle" title="email"></i></a></li> -->
                            </ul><!--end icon-->
                        </div><!--end col-->
                        <div class="lg:col-span-4 md:col-span-4">
                            <h4 class="tracking-[1px] text-gray-100 font-semibold">Service</h4>
                            <ul class="list-none footer-list mt-6">
                                <li><a href="/#features" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Features</a></li>
                                <li class="mt-[10px] ms-0"><a href="/#faq" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> FAQ</a></li>
                                <li class="mt-[10px] ms-0"><a href="/#aboutus" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> About us</a></li>
                                <li class="mt-[10px] ms-0"><a href="/#team" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Team</a></li>
                                <!-- <li class="mt-[10px] ms-0"><a routerLink="/page-pricing" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Pricing</a></li> -->
                            </ul>
                        </div>
                        <!--end col-->
                
                        <div class="lg:col-span-4 md:col-span-4">
                            <h4 class="tracking-[1px] text-gray-100 font-semibold">Usefull Links</h4>
                            <ul class="list-none footer-list mt-6">
                                <li><a routerLink="/terms" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Terms of Services</a></li>
                                <li class="mt-[10px] ms-0"><a routerLink="/privacy" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Privacy Policy</a></li>
                                <!-- <li class="mt-[10px] ms-0"><a routerLink="/changelog" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b"></i> Changelog</a></li> -->
                            </ul>
                        </div><!--end col-->

                    </div><!--end grid-->
                </div><!--end col-->
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative text-center">
            <div class="grid md:grid-cols-2 items-center">
                <div class="md:text-start text-center">
                    <p class="mb-0">© {{date}} AIMEN Shell Toolkit. Design & Develop by <a href="https://www.aimen.es/" target="_blank" class="text-reset">AIMEN</a>.</p>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</footer>
