// @ts-nocheck
import { CommonModule } from '@angular/common';
import { AfterRenderRef, AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavLightComponent } from "../../components/navbar/nav-light/nav-light.component";
import { NgxTypedJsModule } from 'ngx-typed-js';
import { ModalComponent } from 'app/components/modal/modal.component';
import { CountUpModule } from 'ngx-countup';
import { BlogsComponent } from 'app/components/blogs/blogs.component';
import { FooterOneComponent } from 'app/components/footer/footer-one/footer-one.component';
import { CookiesComponent } from 'app/components/cookies/cookies.component';
import { ToolkitApiService } from 'services/toolkit-api.service';
import { ApiProvider } from 'providers/api/api-provider';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NavLightComponent,
    NgxTypedJsModule,
    ModalComponent,
    CountUpModule,
    FooterOneComponent,
    CookiesComponent,
    HttpClientModule,

  ],
  providers: [
    ToolkitApiService,
    ApiProvider
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements AfterViewInit, OnInit {
  total:int=26;
  htmlTag:any = document.getElementsByTagName("html")[0] 
  constructor(
    private toolkitApi: ToolkitApiService
  ) {
    this.getTotalDigitalTwin();
   }

  ngOnInit(): void {
    // this.htmlTag.classList.add("dark")[0];

  }

  getTotalDigitalTwin() {
    this.toolkitApi.getTotalDigitalTwin().subscribe((res) => {
      this.total = res.total;
    })
  }

  ngAfterViewInit(): void {
    // this.htmlTag.classList.add("dark")[0];
  }

  isActive: boolean = false

  openModal(value,e) {
    e.preventDefault();
    
    value.active = true
  }

  closeModal(value) {
    value.active = false
  }

  activetab:any = 1

  onTabClick(e:any,index){
    e.preventDefault()
    this.activetab = index
  }

  goToApplication(){
    this.r
  }

  faq = [
    {
      id:1,
      title:'What is AIMEN Shell Toolkit?',
      desc:`The AIMEN Shell Toolkit is a user-friendly application designed to simplify the creation and management of Asset Administration Shells (AAS). It provides tools for defining, organizing, and visualizing digital twins of physical assets, making it easy for users to bridge operational technology and information systems.`
    },
    {
      id:2,
      title:' Who can use AIMEN Shell Toolkit?',
      desc:`The AIMEN Shell Toolkit is built with an intuitive interface suitable for users of all levels. Whether you’re new to asset administration or experienced in digital twin technology, the editor’s design is accessible and efficient for everyone.`
    },
    {
      id:3,
      title:'How does AIMEN Shell Toolkit support collaboration?',
      desc:`Our application allows you to create and share AASs within designated workspaces. You can invite team members to collaborate, control permissions, manage admin privileges, and remove inactive users to maintain a streamlined workspace.`
    },
    {
      id:4,
      title:'Can I generate OPC UA server configurations with the AIMEN Shell Toolkit?',
      desc:`Yes! The AIMEN Shell Toolkit allows you to generate OPC UA server configurations directly from your AAS models. This feature streamlines the integration of digital twins with industrial systems, making it easier to connect to various operational platforms.`
    },
    {
      id:5,
      title:'How does data validation work in the AIMEN Shell Toolkit?',
      desc:`Built-in data validation ensures that each AAS is configured correctly and complies with necessary standards. This feature minimizes errors and ensures that your AAS meets all required specifications for effective digital twin management.`
    },
    {
      id:6,
      title:'Can I control who can view or edit my AAS?',
      desc:`Absolutely. You can assign specific permissions for each AAS within a workspace, providing control over who can view or edit them. This flexibility allows for secure and efficient team collaboration.`
    },
    {
      id:7,
      title:'How can I add or remove users in a workspace?',
      desc:`You can invite new team members to join your workspace and assign admin privileges as needed. Additionally, unused or inactive users can be removed to maintain a streamlined, focused workspace.`
    }
  ]
  teamData:any = [
    {
      image:'assets/images/team/lucia.webp',
      name:'Lucía Alonso',
      position:'Team Leader',
      desc:"AIMEN",
      bgColor:'bg-sky-600/10 dark:bg-sky-600/30',
      textHover:'hover:text-sky-600'
    },
    {
      image:'assets/images/team/davinia.webp',
      name:'Davinia Fernández',
      position:'Full Stack Developer',
      desc:"AIMEN",
      bgColor:'bg-sky-600/10 dark:bg-sky-600/30',
      textHover:'hover:text-sky-600'
    },
    {
      image:'assets/images/team/lara.webp',
      name:'Lara Barja',
      position:'Full Stack Developer',
      desc:"AIMEN",
      bgColor:'bg-sky-600/10 dark:bg-sky-600/30',
      textHover:'hover:text-sky-600'
    },
    {
      image:'assets/images/team/andres.webp',
      name:'Andrés Pérez',
      position:'Full Stack Developer',
      desc:"AIMEN",
      bgColor:'bg-sky-600/10 dark:bg-sky-600/30',
      textHover:'hover:text-sky-600'
    },
  ]

}

