<app-nav-light/>

 <!-- Start Hero -->
 <section class="relative table w-full lg:py-30 md:py-26 pt-24 pb-24 overflow-hidden bg-white dark:bg-slate-900" id="home">
    <span class="absolute blur-[200px] size-[600px] rounded-full top-1/2 -translate-y-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 bg-sky-500/50"></span>
    <div class="container relative z-1 section">
        <div class="grid md:grid-cols-2 items-center gap-[30px]">
            <div class="">
                <ngx-typed-js [strings]="['Reconfigurable', 'Interoperable','Digital Twin', 'The Future']" [loop]="true"  [showCursor]="false" [typeSpeed]="70">
                    <h4 class="font-bold lg:leading-normal leading-normal text-3xl lg:text-5xl mb-5 text-black dark:text-white relative">AAS Editor <br> We Are <span class="after:absolute after:end-0 after:start-0 after:bottom-4 after:lg:h-4 after:h-3 after:w-auto after:bg-sky-500/30 relative text-sky-500">
                        </span> 
                        <span class="typing text-sky-500"></span>
                    <!-- <br> Programmer. -->
                    </h4>
                </ngx-typed-js>
                <p class="dark:text-slate-300 ligth:text-slate-800 text-lg max-w-xl">User-Friendly Asset Administration Shell (AAS) Editor for Simplified AAS Management and Collaboration</p>
            
                <div class="relative mt-8">
                    <a href="https://app.aimen-shell-toolkit.com" target="_blank" class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-sky-600 hover:bg-sky-700 border-sky-600 hover:border-sky-700 text-white rounded-md me-2">Go to the Application</a>
                    <!-- <a href="#" (click)="openModal(modal,$event)" class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-sky-500 hover:bg-sky-600 border-sky-500 hover:border-sky-600 text-white rounded-full lightbox"><i class="mdi mdi-play text-xl align-middle"></i></a><small class="text-sm font-semibold uppercase align-middle ms-2">Watch Now</small> -->
                </div>
            </div>
            <div class="relative">
                <div class="flex justify-center">
                    <div class="relative overflow-hidden rounded-full md:w-3/4">
                        <source srcset="assets/images/vector01.webp" type="image/webp">
                        <source srcset="assets/images/vector01.png" type="image/png">
                        <img [src]="'assets/images/vector01.webp'" alt="background images">
                        <div class="absolute inset-0 bg-gradient-to-tl to-sky-500/30 from-yellow-600/30"></div>
                    </div>
                </div>

                <div class="absolute top-8 start-0 mover">
                    <source srcset="assets/images/tree.webp" type="image/webp">
                    <source srcset="assets/images/tree.png" type="image/png">
                    <img [src]="'assets/images/tree.webp'" class="rounded-2xl tree" alt="">
                </div>

                <div class="absolute bottom-8 end-0 mover-2">
                    <source srcset="assets/images/logos/aas.webp" type="image/webp">
                    <source srcset="assets/images/logos/aas.png" type="image/png">
                    <img [src]="'assets/images/logos/aas.webp'" class="rounded-2xl" alt="">
                </div>
            </div>
        </div>
    </div><!--end container-->
</section><!--end section-->
<!-- End Hero -->

<!-- Business Partner -->
<!-- <section class="py-6 border-t border-b border-gray-100 dark:border-gray-700">
    <div class="container relative">
        <div class="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
            <div *ngFor="let item of companyImage" class="mx-auto py-4">
                <img [src]="item" class="h-6" alt="">
            </div>
        </div>
    </div>
</section> -->
<!-- Business Partner -->

 <!-- Start -->
 <section class="relative md:py-24 py-16 overflow-hidden" id="features">
    <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div class="relative">
                <div class="relative">
                    <source srcset="assets/images/screenshots/main.webp" type="image/webp">
                    <source srcset="assets/images/screenshots/main.png" type="image/png">
                    <img [src]="'assets/images/screenshots/main.webp'" class="mx-auto md:max-w-xs lg:max-w-sm rounded-lg shadow-md dark:shadow-gray-800" alt="">
                    <!-- 
                    <div class="absolute top-4 md:start-12 -start-2 text-center">
                        <a href="#!" (click)="openModal(modal,$event)"
                            class="lightbox size-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center text-white bg-sky-500">
                            <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                        </a>
                    </div> -->
                </div>
                <div class="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-sky-500/20 via-sky-500/70 from-sky-500 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-sky-500/10 rounded-full"></div>

                <div class="absolute flex justify-between items-center bottom-10 md:-end-10 -end-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-56 m-3">
                    <div class="flex items-center justify-center h-[65px] min-w-[65px] bg-sky-500/5 text-sky-500 text-center rounded-full me-3">
                        <source srcset="assets/images/logos/aas.webp" type="image/webp">
                        <source srcset="assets/images/logos/aas.png" type="image/png">
                        <img [src]="'assets/images/logos/aas.webp'" class="rounded-2xl mini" alt="">
                    </div>
                    <div class="flex-1">
                        <h3 class="dark:text-slate-300 ligth:text-slate-800">AAS's</h3>
                        <p class="text-xl font-bold"><span class="counter-value" [countUp]="total"></span></p>
                    </div>
                </div>
            </div>

            <div class="lg:ms-8">
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Human centric AAS editor</h3>
                <p class="dark:text-slate-300 ligth:text-slate-800 max-w-xl">You can combine all the AIMEN Shell Toolkit templates into a single one, you can take a component from the Application theme and use it in the Website.</p>

                <ul class="list-none dark:text-slate-300 ligth:text-slate-800 mt-4">
                    <li class="mb-1 inline-block"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>User-Friendly Interface:</b> The editor is designed to be intuitive and accessible, making AAS creation and management easy for all users.</li>
                    <li class="mb-1 inline-block"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>Robust Data Validation:</b> Built-in data validation ensures that each AAS is configured accurately and meets necessary standards.</li>
                    <li class="mb-1 inline-block"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>OPC UA Server Configuration:</b> Generate OPC UA server configurations directly from your AAS models, streamlining integration with industrial systems.</li>
                </ul>

                <!-- <div class="mt-4">
                    <a [routerLink]="'/page-aboutus'" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                </div> -->
            </div>
        </div><!--end grid-->
    </div><!--end container-->
    <section class="relative md:py-24 py-16 overflow-hidden">    
        <div class="container relative md:mt-24 mt-16">
            <div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why Everyone Loves AIMEN Shell Toolkit</h3>
                <p class="dark:text-slate-300 ligth:text-slate-800 max-w-xl mx-auto">Our application simplifies the creation of an Asset Administration Shell (AAS) through an intuitive, step-by-step guided form.</p>
            </div><!--end grid-->
    
            <div class="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                <div class="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <source srcset="assets/images/screenshots/create.webp" type="image/webp">
                    <source srcset="assets/images/screenshots/create.png" type="image/png">
                    <img src="assets/images/screenshots/create.webp" class="rounded-lg shadow-md dark:shadow-gray-800" alt="">
                    <div class="overflow-hidden absolute lg:size-[400px] size-[320px] bg-sky-500/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                </div>
    
                <div class="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                    <h4 class="mb-4 text-2xl leading-normal font-medium">Create new AAS</h4>
                    <p class="dark:text-slate-300 ligth:text-slate-800">This feature helps ensure that all necessary data for the AAS is included with minimal effort, making it ideal for users who are new to the process. </p>
                    <ul class="list-none dark:text-slate-300 ligth:text-slate-800 mt-4">
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>User-Friendly Guidance:</b>  A step-by-step process makes AAS creation easy and understandable.</li>
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>Data Validation:</b>  Each stage checks data accuracy, ensuring that only complete, viable information is submitted.</li>
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i> <b>Efficient Completion:</b> Create a minimum viable Asset Administration Shell quickly, meeting essential requirements without overwhelming details.</li>
                    </ul>
    
                    <!-- <div class="mt-4">
                        <a routerLink="/page-aboutus" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                    </div> -->
                </div>
            </div>
        </div><!--end container-->
    
        <div class="container relative md:mt-24 mt-16">
            <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                <div class="relative order-1 md:order-2 wow animate__animated animate__fadeInRight" data-wow-delay=".5s">
                    <source srcset="assets/images/screenshots/groups.webp" type="image/webp">
                    <source srcset="assets/images/screenshots/groups.png" type="image/png">
                    <img src="assets/images/screenshots/groups.webp" class="rounded-lg shadow-md dark:shadow-gray-800" alt="">
                    <div class="overflow-hidden absolute lg:size-[400px] size-[320px] bg-sky-500/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl"></div>
                </div>    
                <div class="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                    <h4 class="mb-4 text-2xl leading-normal font-medium">Share AAS's</h4>
                    <p class="dark:text-slate-300 ligth:text-slate-800">Easily create and share Asset Administration Shells (AAS) within designated workspaces, enabling collaborative management of digital twins. This feature allows you to control who can view or edit each AAS, providing a secure and flexible environment for team collaboration.</p>
                    <ul class="list-none dark:text-slate-300 ligth:text-slate-800 mt-4">
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>Invite New Users to Your Workspace:</b> Add team members to your workspace and empower them to work on shared AAS's.</li>
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>Manage Admin Privileges:</b> Control user permissions with admin privileges, ensuring the right level of access for each team member.</li>
                        <li class="mb-1 inline-block ms-0"><i class="uil uil-check-circle text-sky-500 text-xl me-2"></i><b>Remove Unused Users:</b> Maintain a streamlined workspace by deleting users who are no longer active.</li>
                    </ul>
    
                    <!-- <div class="mt-4">
                        <a routerLink="/page-aboutus" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                    </div> -->
                </div>
            </div>
        </div><!--end container-->

    </section>

<!-- Start -->
<!-- <section class="py-20 w-full table relative bg-[url('/assets/images/programming/bg.jpg')] bg-fixed bg-center bg-no-repeat bg-cover">
    <div class="absolute inset-0 bg-gradient-to-tl to-sky-500/75 from-yellow-600/75"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 text-center">
            <h3 class="mb-4 md:text-4xl text-3xl text-white font-semibold">We Are A Talented Developer or Programmer.</h3>

            <p class="text-white/70 max-w-xl mx-auto">Start working with AIMEN Shell Toolkit that can provide everything you need to generate awareness, drive traffic, connect.</p>
        
            <a href="#!" (click)="openModal(modal,$event)"  class="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-sky-500 mx-auto mt-10">
                <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
            </a>
        </div>
    </div>
</section> -->
<!-- End -->

<!-- Start -->
<section class="relative md:py-24 py-16" id="faq">
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions</h3>

            <!-- <p class="dark:text-slate-300 ligth:text-slate-800 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
        </div><!--end grid-->

        <div id="accordion-collapse" data-accordion="collapse" class="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
            <div>
                <div *ngFor="let item of faq | slice:0:4" class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mb-4">
                    <h2 class="text-base font-semibold">
                        <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-start" [ngClass]="activetab === item.id ? 'bg-gray-50 dark:bg-slate-800 text-sky-500' : ''" (click)="onTabClick($event,item.id)">
                            <span>{{item.title}}</span>
                            <svg data-accordion-icon class="size-4 shrink-0" [ngClass]="activetab === item.id ? 'rotate-180' : ''" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                            </svg>
                        </button>
                    </h2>
                    <div [ngClass]="activetab === item.id ? '' : 'hidden'">
                        <div class="p-5">
                            <p class="dark:text-slate-300 ligth:text-slate-800 dark:text-gray-400">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div *ngFor="let item of faq | slice:4:8" class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mb-4">
                    <h2 class="text-base font-semibold">
                        <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-start" [ngClass]="activetab === item.id ? 'bg-gray-50 dark:bg-slate-800 text-sky-500' : ''" (click)="onTabClick($event,item.id)">
                            <span>{{item.title}}</span>
                            <svg data-accordion-icon class="size-4 shrink-0" [ngClass]="activetab === item.id ? 'rotate-180' : ''" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                            </svg>
                        </button>
                    </h2>
                    <div [ngClass]="activetab === item.id ? '' : 'hidden'">
                        <div class="p-5">
                            <p class="dark:text-slate-300 ligth:text-slate-800 dark:text-gray-400">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="relative table w-full py-36 lg:py-44 bg-[url('/assets/images/company/img-sede.webp')] bg-no-repeat bg-center bg-cover" id="aboutus">
    <div class="absolute inset-0 bg-black opacity-75"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">About Us</h3>

            <p class="text-slate-300 text-lg max-w-xl mx-auto">Our web application serves as an Asset Administration Shell (AAS) Editor, designed to streamline asset management through an intuitive, user-friendly interface. This tool empowers users to define, organize, and visualize digital representations of physical assets, bridging the gap between operational technology and information systems.</p>
        </div>
    </div>
</section><!--end section-->
<div class="relative">
    <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="lg:col-span-5 md:col-span-6">
                <div class="grid grid-cols-12 gap-6 items-center">
                    <div class="col-span-12">
                        <div class="grid grid-cols-1 gap-6">
                            <img src="assets/images/logos/ZeroSWARM-logo-black-1.webp" class="shadow rounded-md dark:hidden" alt="zeroswarm logo black">
                            <img src="assets/images/logos/ZeroSWARM-logo-white-1.webp" class="shadow rounded-md hidden dark:inline-block" alt="zeroswarm logo white">

                        </div>
                    </div>


                    <div class="col-span-12">
                        <div class="grid grid-cols-1 gap-6">
                            <img src="assets/images/logos/AimenEngLogRGB.webp" class="shadow rounded-md dark:hidden" alt="aimen logo dark">
                            <img src="assets/images/logos/AimenEngLogBlanco.webp" class="shadow rounded-md hidden dark:block" alt="aimen logo white">
                        </div>
                    </div>

                    <div class="col-span-12">
                        <div class="grid grid-cols-1 gap-6">
                            <img src="assets/images/logos/european-commission.svg" class="euro-logo shadow rounded-md" alt="european logo">
                        </div>
                    </div>
                </div>
            </div>

            <div class="lg:col-span-7 md:col-span-6">
                <div class="lg:ms-5">
                    <div class="flex mb-4">
                        <span class="text-sky-500 text-2xl font-bold mb-0"><span class="counter-value text-6xl font-bold" [countUp]="15">1</span>+</span>
                        <span class="self-end font-medium ms-2">Years <br> Experience</span>
                    </div>

                    <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Who we are ?</h3>

                    <p class="dark:text-slate-300 ligth:text-slate-800 max-w-xl">As a proud partner developer within the Zero-SWARM initiative, we contribute to an ambitious project aimed at transforming European manufacturing. Zero-SWARM, short for Zero-enabling smart networked control framework for agile cyber-physical production systems of systems, launched on June 1, 2022, with a substantial public-private investment of nearly €10 million. The project aims to integrate advanced 5G and cloud-edge technologies, creating frameworks that enhance manufacturing agility and sustainability across Europe.</p>
                    <br>
                    <p class="dark:text-slate-300 ligth:text-slate-800 max-w-xl">With its focus on achieving climate-neutral, digitized production, Zero-SWARM champions a human-centered, multidisciplinary approach to innovation. Through 10 industry-driven trials, this initiative coalesces emerging technologies, such as 5G, cloud-edge, data analytics, and digital twins, into a cohesive, open framework for non-public 5G networks, active information continuums, and enhanced automation.</p>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
<section class="relative md:py-24 py-16" id="team">
    <div class="container relative md:my-24 my-16">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Team</h3>
        </div><!--end grid-->

        <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-8 gap-[30px]">
            <div *ngFor="let item of teamData" class="lg:col-span-3 md:col-span-6">
                <div class="team p-6 rounded-md shadow-md dark:shadow-gray-800 dark:border-gray-700 bg-white dark:bg-slate-900 relative">
                    <div class="absolute inset-0 rounded-md -mt-[10px] -ms-[10px] size-[98%] -z-1" [ngClass]="item.bgColor"></div>
                    <img [src]="item.image" class="size-24 rounded-full shadow-md dark:shadow-gray-800" alt="">

                    <div class="content mt-4">
                        <a routerLink="#" class="text-lg font-medium block" [ngClass]="item.textHover">{{item.name}}</a>
                        <span class="text-slate-400 block">{{item.position}}</span>

                        <p class="text-slate-400 mt-4">{{item.desc}}</p>

                        <!-- <ul class="list-none mt-4">
                            <li class="inline"><a routerLink="#" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><i data-feather="facebook" class="size-4"></i></a></li>
                            <li class="inline"><a routerLink="#" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><i data-feather="instagram" class="size-4"></i></a></li>
                            <li class="inline"><a routerLink="#" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><i data-feather="twitter" class="size-4"></i></a></li>
                            <li class="inline"><a routerLink="#" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><i data-feather="linkedin" class="size-4"></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div><!--end container-->
</section>
<!-- End -->
<app-footer-one/>

<app-cookies/>

<app-modal #modal [active]="isActive">
    <!-- <iframe width="100%" height="100%"
        src="https://www.youtube.com/embed/S_CGed6E610">
        </iframe> -->
    <button (click)="closeModal(modal)" class="absolute top-[2%] right-[2%] bg-white text-gray-500 size-8 rounded-full">
        <span class="mdi mdi-close"></span>
    </button>
</app-modal>
