<nav id="topnav" class="defaultscroll is-sticky" (window:scroll)="windowScroll()">
    <div class="container relative">
        <!-- Logo container-->
        <a class="logo" routerLink="/" aria-label="Go home appplication">
            <source srcset="assets/images/logos/aimenShellToolkit.webp" type="image/webp">
            <source srcset="assets/images/logos/aimenShellToolkit.png" type="image/png">
            <img src="assets/images/logos/aimenShellToolkit.webp" class="inline-block dark:hidden" alt="aimen logo">
            <source srcset="assets/images/logos/AimenshellEngLogBlanco.webp" type="image/webp">
            <source srcset="assets/images/logos/AimenshellEngLogBlanco.png" type="image/png">
            <img src="assets/images/logos/AimenshellEngLogBlanco.webp" class="hidden dark:inline-block" alt="aimen logo">
        </a>

        <!-- End Logo container-->
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->
        <ul class="buy-button list-none mb-0">
            <li class="inline ps-1 mb-0">
                <a href="https://app.aimen-shell-toolkit.com" target="_blank" class="py-2 px-5 font-semibold inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-sky-600 hover:bg-sky-700 border border-sky-600 hover:border-sky-700 text-white rounded-md me-2">Application</a>
            </li>
        </ul>
        <!--Login button End-->

        <div id="navigation" [style]="status  ? 'display: block;' : 'display: none;'">
            <!-- Navigation Menu-->   
            <ul class="navigation-menu">
                <li [ngClass]="activeManu === '/' ? 'active' : ''"><a href="#home" class="sub-menu-item">Home</a></li>
                <li class="has-submenu features">
                    <a href="#features" class="sub-menu-item">Features</a>
                </li>
                <li class="has-submenu review">
                    <a href="#faq" class="sub-menu-item">FAQ</a>
                </li>
                <li class="has-submenu review">
                    <a href="#aboutus" class="sub-menu-item">About Us</a>
                </li>
                <li class="has-submenu review">
                    <a href="#team" class="sub-menu-item">Team</a>
                </li>
                <!-- <li><a routerLink="/contact-one" class="sub-menu-item">Contact</a></li> -->
            </ul><!--end navigation menu-->
        </div><!--end navigation-->
    </div><!--end container-->
</nav>
