<a routerLink="/" (window:scroll)="onWindowScroll()" (click)="scrollToTop()" id="back-to-top"
    class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 size-9 text-center bg-sky-500 text-white leading-9"><i
        class="uil uil-arrow-up leading-10"></i></a>
<!-- Back to top -->

<!-- Switcher -->
<div class="fixed top-[50%] -right-2 z-50">
    <span class="relative inline-block rotate-90">
        <input type="checkbox" class="checkbox opacity-0 absolute" id="chk" (change)="changeTheme()" />
        <label
            class="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
            for="chk">
            <i class="uil uil-moon text-[20px] text-yellow-600"></i>
            <i class="uil uil-sun text-[20px] text-yellow-600"></i>
            <span class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"></span>
        </label>
    </span>
</div>