<app-nav-light/>

<section class="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">AIMEN Personal Data Protection Policy</h3>
        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-sky-500"><a routerLink="/">AIMEN Shell Toolkit</a></li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="uil uil-angle-right-b"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold text-sky-500" aria-current="page">Privacy</li>
        </ul>
    </div>
</section><!--end section-->
<div class="relative">
    <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="md:flex justify-center">
            <div class="md:w-3/4">
                <div class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                    <div class="dark:text-slate-300 ligth:text-slate-800">
                        <h4 class="text-xl font-semibold mb-4">AIMEN Personal Data Protection Policy</h4>
                        <p>In compliance with current regulations on data protection, consisting of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (GDPR) and the Organic Law 3/2018 of 5 December, on Data Protection and Guarantee of Digital Rights, you are hereby provided with the following information about the data processing carried out by AIMEN:</p>
                        
                        <h5 class="text-lg font-semibold mb-2 mt-6">Personal Data Controller</h5>
                        <p>The personal data controller is the legal person determining the purposes and means of the processing of personal data. In other words, the controller decides how and why personal data are processed.</p>
                        <p>For the purposes of this Personal Data Protection Policy, the personal data controller is:</p>
                        <ul class="list-disc list-inside">
                            <li>Identity: ASOCIACION DE INVESTIGACION METALÚRGICA DEL NOROESTE (AIMEN). NIF (Spanish Tax ID number): G36606291</li>
                            <li>Address: C/ Relva 27 A, 36410 TORNEIROS-PORRIÑO (Pontevedra)</li>
                            <li>Contact telephone number: 986 34 40 00</li>
                            <li>Contact e-mail: privacidad&#64;aimen.es</li>
                            <li>Privacy Officer: privacidad&#64;aimen.es</li>
                            <li>Data Protection Delegate: dpo&#64;aimen.es</li>
                        </ul>

                        <h5 class="text-lg font-semibold mb-2 mt-6">What personal data do we process and how do we protect the data?</h5>
                        <p>“Personal data” is any information relating to an identified or identifiable natural person.</p>
                        <p>For the purposes hereof, the data controller collects and processes the data explained in each type of processing, which will be subject to the services requested or to the contractual relationship you have with our organization.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>You guarantee and are liable for, at all times, the truthfulness, accuracy, validity and authenticity of the personal data provided and undertake to keep such data duly updated.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">Processing of personal data from “Clients”</h5>
                        <p><strong>What categories of personal data do we process?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Identification data: name, ID number, address, telephone number, e-mail address.</li>
                            <li>Financial data: bank account number, credit card details.</li>
                            <li>Academic and professional data: occupation, position, experience, qualifications.</li>
                            <li>Data on transactions: supplied products and services.</li>
                        </ul>
                        <p><strong>Why do we process your personal data?</strong></p>
                        <p>We process your personal data to facilitate the management of our clients’ data, to maintain our business relationship, our accounting, administrative and invoicing activities, and our tax obligations.</p>
                        <p>We also intend to use the data for advertising and sales prospection, wherefor the data subject is requested his or her express consent.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>The personal data provided will be retained for the duration of our business relationship. If you decide to cancel your personal data, those may be retained in our data bases for the period stipulated by law to meet our tax and accounting obligations. They will be deleted once such legal time limits or the applicable time limits have elapsed.</p>
                        <p><strong>What are the legal grounds for processing your data?</strong></p>
                        <p>The legal grounds for processing your data is the subscription of a contract, as well as the fulfillment of the controller’s legal obligations.</p>
                        <p><strong>Who will be communicated your data?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Gestoría G5; company in charge of accounting and tax management</li>
                            <li>Ayco.net; company in charge of informative mailing activities.</li>
                        </ul>
                        <p>Your personal data will not be transferred to any other organizations, except to public bodies if so requested by law.</p>
                        <p>No international personal data transfers are intended.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">Processing of personal data from “Contacts and prospective clients”</h5>
                        <p><strong>What categories of personal data do we process?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Identification data: name, address, telephone number, e-mail address.</li>
                        </ul>
                        <p><strong>Why do we process your personal data?</strong></p>
                        <p>We process the personal data you provide in the contact form to manage the data of business contacts and prospects.</p>
                        <p>We also intend to use the data for advertising and sales prospection, wherefor the data subject is requested his or her consent.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>The personal data provided will be retained for the duration of our business relationship. If you decide to cancel your personal data, they will be deleted from our contact data base.</p>
                        <p><strong>What are the legal grounds for processing your data?</strong></p>
                        <p>The legal grounds for processing your data is the express consent requested to you.</p>
                        <p><strong>Who will be communicated your data?</strong></p>
                        <p>Your personal data will not be transferred to third parties.</p>
                        <p>No international personal data transfers are intended.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">Processing of personal data from “Suppliers”</h5>
                        <p><strong>What categories of personal data do we process?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Identification data: name, ID number, address, telephone number, e-mail address.</li>
                            <li>Financial data: bank account number.</li>
                            <li>Academic and professional data: occupation, position, qualifications.</li>
                            <li>Data on transactions: supplied products and services.</li>
                        </ul>
                        <p><strong>Why do we process your personal data?</strong></p>
                        <p>We process your personal data to facilitate the management of our suppliers’ data, to maintain our business relationship, our accounting, administrative and payment activities, and our tax obligations.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>The personal data provided will be retained for the duration of our business relationship. If you decide to cancel your personal data, those may be retained in our data bases for the time stipulated by law to meet our tax and accounting obligations. They will be deleted once such legal time limits or the applicable time limits have elapsed.</p>
                        <p><strong>What are the legal grounds for processing your data?</strong></p>
                        <p>The legal grounds for processing your data is the subscription of an agreement, as well as the fulfillment of the controller’s legal obligations.</p>
                        <p><strong>Who will be communicated your data?</strong></p>
                        <p>Your personal data will not be transferred to any third-party organizations, except to public bodies if so requested by law.</p>
                        <p>No international personal data transfers are intended.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">Processing of personal data from “Candidates”</h5>
                        <p><strong>What categories of personal data do we process?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Identification data: name, ID number, date of birth, address, telephone number, e-mail address, image.</li>
                            <li>Data on personal characteristics: Sex, marital status, nationality, age, date and place of birth.</li>
                            <li>Academic and professional data: occupation, position, experience, qualifications.</li>
                        </ul>
                        <p><strong>Why do we process your personal data?</strong></p>
                        <p>We process the personal data you provide to us for recruitment purposes.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>The personal data provided will be retained as long as the data subject withdrawn consent to keep his or her CV for future recruitment processes. If you decide to cancel your personal data, they will be deleted from our candidate data base.</p>
                        <p><strong>What are the legal grounds for processing your data?</strong></p>
                        <p>The legal grounds for processing your data is the express consent requested to you.</p>
                        <p><strong>Who will be communicated your data?</strong></p>
                        <p>Your personal data will not be transferred to third parties.</p>
                        <p>No international personal data transfers are intended.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">Processing of personal data from “Training”</h5>
                        <p><strong>What categories of personal data do we process?</strong></p>
                        <ul class="list-disc list-inside">
                            <li>Identification data: name, ID number, date of birth, address, telephone number, e-mail address, image.</li>
                            <li>Data on personal characteristics: Sex, marital status, nationality, age, date and place of birth.</li>
                            <li>Academic and professional data: occupation, position, experience, qualifications.</li>
                            <li>Data on transactions: supplied products and services.</li>
                        </ul>
                        <p><strong>Why do we process your personal data?</strong></p>
                        <p>We process your personal data to facilitate the management of training courses that are taught at AIMEN.</p>
                        <p>Our organization undertakes to process the data in full confidentiality and to apply the appropriate physical, technical and organizational safeguards to protect your personal data.</p>
                        <p>The personal data provided will be retained as long as the data subject withdrawn consent for management of future training courses. If you decide to cancel your personal data, they will be deleted from our data base.</p>
                        <p><strong>What are the legal grounds for processing your data?</strong></p>
                        <p>The legal grounds for processing your data is the express consent requested to you.</p>
                        <p><strong>Who will be communicated your data?</strong></p>
                        <p>Your data may be communicated to entities in charge of data processing for the issuance of certificates, such as FROSIO, CESOL and AEND.</p>
                        <p>Your personal data will not be transferred to third parties.</p>
                        <p>No international personal data transfers are intended.</p>

                        <h5 class="text-lg font-semibold mb-2 mt-6">What are your rights when you provide your data to us?</h5>
                        <p>According to applicable regulations on personal data protection, you are entitled to a number of rights regarding the processing of your personal data. You may exercise such rights free of charge, unless those are clearly unreasonable or excessive, or particularly repetitive.</p>
                        <p>Your rights are listed below:</p>
                        <ul class="list-disc list-inside">
                            <li><strong>Right to information:</strong> You have the right to be informed in a concise, transparent, intelligible and easily accessible form, using clear and plain language, about the use and processing of your personal data.</li>
                            <li><strong>Right to access:</strong> You have the right to request confirmation at any time about whether we are processing your personal data, to be provided access to such data and to be informed about their processing, and to obtain a copy of the data. The copy of your personal data provided by us will be free of charge. However, any additional copies may be subject to the payment of a reasonable amount based on the administrative costs incurred. We may ask you to prove your identity or require any necessary information to manage your request.</li>
                            <li><strong>Right of rectification:</strong> You have the right to obtain the rectification of inaccurate, outdated or incomplete data concerning you. You may also have the incomplete personal data completed, including by means of providing a supplementary statement.</li>
                            <li><strong>Right to erasure:</strong> You have the right to obtain the erasure of your personal data when, among other reasons, the data are no longer necessary in relation to the purposes for which they were collected. However, this right is not an absolute right, so our organization may keep them, if duly blocked, as stipulated under applicable regulations.</li>
                            <li><strong>Right to restriction of processing:</strong> You have the right to obtain restriction of processing of your personal data, which means we may store such data but not process them if any of the following conditions apply:
                                <ul class="list-disc list-inside ml-4">
                                    <li>You contest the accuracy of the personal data, for a period enabling the controller to verify their accuracy;</li>
                                    <li>The processing is unlawful and you oppose the erasure of the personal data and request the restriction of their use instead;</li>
                                    <li>Our company no longer needs the personal data for the purposes of processing, but they are required by you for the establishment, exercise or defense of legal claims;</li>
                                    <li>You have objected to processing, pending the verification whether our legitimate grounds override yours.</li>
                                </ul>
                            </li>
                            <li><strong>Right to data portability:</strong> You have the right to receive your personal data in a structured, commonly used and machine-readable format to transmit those data to another controller. This right is applicable when the processing of your personal data is based on consent or on the subscription of an agreement and if such processing is carried out by automated means.</li>
                            <li><strong>Right to object:</strong> This right allows you to object to the processing of your personal data, including profiling. We will not be able to observe your right solely when we process your data in the event we can prove legitimate grounds for processing or for establishing, exercising or defending legal claims.</li>
                            <li><strong>Right to object to automated decision-making, including profiling:</strong> This right allows you to not be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affecting you. Unless such decision is necessary for entering into, or performance of, a contract between you and us, is authorized by law or is based on your explicit consent.</li>
                            <li><strong>Right to withdraw consent:</strong> In the event we have obtained your consent for processing your personal data regarding certain activities (e.g. to send you sales communications), you may withdraw such consent at any time. Thus, we will cease to carry out the specific activity you had consented in the past, unless the continuity of the processing of your personal data is otherwise justified, in which case you will be notified.</li>
                            <li><strong>Right to lodge a complaint with a supervisory authority:</strong> You have the right to lodge a complaint before Spanish Data Protection Agency, C/ Jorge Juan, 6, 28001 Madrid, 901 100 099 - 912 663 517 (www.agpd.es), or at: <a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formQuejasSugerencias/seleccionarQuejaSugerencia.jsf" target="_blank">https://sedeagpd.gob.es/sede-electronica-web/vistas/formQuejasSugerencias/seleccionarQuejaSugerencia.jsf</a></li>
                        </ul>
                        <p>You may exercise the rights above by sending a communication to the controller’s mailing address or e-mail address, together with a document proving your identity and providing any details necessary to process your request.</p>
                        <p>The data subjects may obtain additional information on their rights from the website of the Spanish Data Protection Agency, www.agpd.es.
                        </p>
                    </div>
                </div><!--end -->
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</section>
<app-footer-one/>

