import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ErrorComponent } from './pages/error/error.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';

export const routes: Routes = [
    {'path':'', component:HomeComponent},
    {'path':'terms', component:TermsComponent},
    {'path':'privacy', component:PrivacyComponent},
    {'path':'contact', component:ContactComponent},
    {'path':'error', component:ErrorComponent},
    {'path':'**', redirectTo:'error'}
];
