<app-nav-light/>
<section class="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">Terms of Service</h3>
        </div>
    </div>

    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-sky-500">
                <a routerLink="/">AIMEN Shell Toolkit</a>
            </li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i class="uil uil-angle-right-b"></i>
            </li>
            <li class="inline-block uppercase text-[13px] font-bold text-sky-500" aria-current="page">Terms</li>
        </ul>
    </div>
</section>

<div class="relative">
    <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="md:flex justify-center">
            <div class="md:w-3/4">
                <div class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                    <h5 class="text-xl font-semibold mb-4">Introduction</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        Welcome to aimen-shell-toolkit.com, a platform provided by ASOCIACION DE INVESTIGACION METALÚRGICA DEL NOROESTE (hereinafter, AIMEN), located at 
                        <a href="http://www.aimen.es/">http://www.aimen.es/</a>. This platform enables users to edit and manage asset administration shells (AAS). By accessing or using our services, you agree to comply with and be bound by the following Terms and Conditions. Please review these Terms carefully before using our platform.
                    </p>

                    <h5 class="text-xl font-semibold mb-4 mt-8">Identifying Details</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        In compliance with article 10 of the Information Society and E-Commerce Services Act 34/2002, AIMEN, with address at c/ Relva 27 A, 36410 TORNEIROS-PORRIÑO (Pontevedra), Tax Identification Code (C.I.F): G36606291, and contact information (email: 
                        <a href="mailto:aimen&#64;aimen.es">aimen&#64;aimen.es</a>, telephone: 986344000), is the titleholder of this webpage.
                    </p>

                    <h5 class="text-xl font-semibold mb-4 mt-8">Acceptance of Terms</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        By registering for an account and/or using the services on this platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms, you must not use our services.
                    </p>

                    <h5 class="text-xl font-semibold mb-4 mt-8">User Obligations and Responsibility</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        The USER accepts responsibility for the use of the platform. This responsibility includes the need to register with accurate and lawful information and to keep any password provided for access secure and confidential. The USER agrees to:
                    </p>
                    <ul class="dark:text-slate-300 light:text-slate-800 list-disc list-inside ml-6">
                        <li>Use the platform responsibly and refrain from any illegal or harmful activities, including but not limited to distributing racist, xenophobic, illegal-pornographic content, promoting terrorism, or violating human rights.</li>
                        <li>Not introduce or spread computer viruses or other harmful systems that could damage AIMEN’s platform or systems.</li>
                        <li>Respect the rights and privacy of other users, refraining from unauthorized access or use of others’ accounts or data.</li>
                    </ul>

                    <h5 class="text-xl font-semibold mb-4 mt-8">Intellectual and Industrial Property</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        AIMEN, as the titleholder or licensee, owns all intellectual and industrial property rights to this website and its content (e.g., images, audio, software, trademarks). The USER is permitted to view and store elements on the site solely for personal, private use. Any other use, including transformation, distribution, or public display of content, is prohibited without AIMEN’s authorization.
                    </p>

                    <h5 class="text-xl font-semibold mb-4 mt-8">User-Generated Content</h5>
                        <li>
                            <p class="dark:text-slate-300 light:text-slate-800">
                                <strong>Sharing of Information</strong><br>
                                User-Generated Content on this platform may be viewed by other registered users. By using our services, you consent to this sharing. AIMEN does not endorse or verify the accuracy of User-Generated Content.
                            </p>
                        </li>
                        <li>
                            <p class="dark:text-slate-300 light:text-slate-800 mt-4">
                                <strong>Responsibility for User-Generated Content</strong><br>
                                Users are solely responsible for their own content. AIMEN is not responsible for any User-Generated Content shared on the platform.
                            </p>
                        </li>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Disclaimer of Warranties</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN’s services are provided "as is" and "as available," with no warranties, express or implied. AIMEN disclaims all warranties, including those of merchantability, fitness for a particular purpose, and non-infringement.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Limitation of Liability</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN is not liable for any indirect, incidental, special, consequential, or punitive damages resulting from:
                        </p>
                        <ul class="dark:text-slate-300 light:text-slate-800 list-disc list-inside ml-6">
                            <li>Your use of or inability to use the platform.</li>
                            <li>Unauthorized access to personal information.</li>
                            <li>Any User-Generated Content or conduct of any third party on the platform.</li>
                        </ul>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Exclusion of Guarantees and Liability</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN is not responsible for the content, availability, or accuracy of linked sites. Although links are regularly monitored, AIMEN is not liable for illegal or harmful content from external links or websites.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Indemnification</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            You agree to indemnify and hold AIMEN harmless from any claims, losses, or damages arising from your use or misuse of our services or from any violation of these Terms.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Use of Cookies</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN may use cookies to optimize user navigation. Cookies are associated only with anonymous users and do not reveal personal data. Users can configure their browsers to reject cookies.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Data Protection Policy</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            Personal data collected on this website will be treated according to applicable data protection laws. For more details, refer to AIMEN's Privacy Policy.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Modifications to Terms</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN reserves the right to modify these Terms at any time without prior notice. Your continued use of the platform after changes constitutes acceptance of the new Terms.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Governing Law and Jurisdiction</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            The relationship between AIMEN and the USER shall be governed by Spanish law, and any disputes will be resolved in the courts of Vigo.
                        </p>

                        <h5 class="text-xl font-semibold mb-4 mt-8">Right of Exclusion</h5>
                        <p class="dark:text-slate-300 light:text-slate-800">
                            AIMEN reserves the right to deny or withdraw access to the platform and services for users who breach these General Conditions.
                        </p>

                    <!-- Continue with additional sections following the same structure -->

                    <h5 class="text-xl font-semibold mb-4 mt-8">Contact Information</h5>
                    <p class="dark:text-slate-300 light:text-slate-800">
                        If you have questions about these Terms, please contact us at 
                        <a href="mailto:aimen&#64;aimen.es">aimen&#64;aimen.es</a>.
                    </p>

                    <p class="dark:text-slate-300 light:text-slate-800 mt-8">
                        aimen-shell-toolkit.com is a service provided by AIMEN. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-one/>
