<section class="relative bg-sky-500/5">
    <div class="container-fluid relative">
        <div class="grid grid-cols-1">
            <div class="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
                <div class="text-center">
                    <a class="" routerLink="/" aria-label="Go home appplication">
                        <source srcset="assets/images/logos/aimenShellToolkit.webp" type="image/webp">
                        <source srcset="assets/images/logos/aimenShellToolkit.png" type="image/png">
                        <img src="assets/images/logos/aimenShellToolkit.webp" class="logo inline-block dark:hidden" alt="aimen logo">
                        <source srcset="assets/images/logos/AimenshellEngLogBlanco.webp" type="image/webp">
                        <source srcset="assets/images/logos/AimenshellEngLogBlanco.png" type="image/png">
                        <img src="assets/images/logos/AimenshellEngLogBlanco.webp" class="logo hidden dark:inline-block" alt="aimen logo">
                    </a>
                </div>
                <div class="title-heading text-center my-auto">
                    <img src="assets/images/404.gif" class="img404 mx-auto" alt="">
                    <h1 class="mt-3 mb-6 md:text-5xl text-3xl font-bold">Page Not Found?</h1>
                    <p class="dark:text-slate-300 ligth:text-slate-800">Whoops, this is embarassing. <br> Looks like the page you were looking for wasn't found.</p>
                    
                    <div class="mt-4">
                        <a routerLink="/" class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-sky-500 hover:bg-sky-600 border-sky-500 hover:border-sky-600 text-white rounded-md">Back to Home</a>
                    </div>
                </div>
                <div class="text-center">
                    <p class="mb-0">© {{date}} AIMEN Shell Toolkit. Design & Develop with <i class="mdi mdi-heart text-red-600"></i> by <a href="https://aimen.es" target="_blank" class="text-reset">AIMEN</a>.</p>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
