import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FooterOneComponent } from 'app/components/footer/footer-one/footer-one.component';
import { NavLightComponent } from 'app/components/navbar/nav-light/nav-light.component';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [
    CommonModule,
    FooterOneComponent,
    NavLightComponent
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {

}
