
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ApiProvider {
  constructor(
    public http: HttpClient
    ) {

  }

  get(url:string, endpoint: string, options?: any) {
    return this.http.get<any>(url + '/' + endpoint, options);
  }

  post(url:string, endpoint: string, body: any, options?: any) {
    
    return this.http.post<any>(url  + '/' + endpoint, body, options);
  }


  put(url:string, endpoint: string, body: any, options?: any) {
    return this.http.put(url  +  '/' + endpoint, body, options);
  }

  delete(url:string, endpoint: string, options?: any) {
    
    return this.http.delete(url  +  '/' + endpoint, options);
  }

  patch(url:string, endpoint: string, body: any, options?: any) {
    return this.http.put(url  +  '/' + endpoint, body, options);
  }

  getFile(url:string, endpoint: string, options?: any) {
    return this.http.get<Blob>(url  +  '/' + endpoint, { observe: 'response', responseType: 'blob' as 'json' })
  }
  postFile(url:string, endpoint: string, options?: any) {
    return this.http.post<Blob>(url  +  '/' + endpoint, { observe: 'response', responseType: 'blob' as 'json' })
  }
}
