import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterLink, Router } from '@angular/router';

@Component({
  selector: 'app-nav-light',
  standalone: true,
  imports: [CommonModule,RouterLink],
  templateUrl: './nav-light.component.html',
  styleUrls: ['./nav-light.component.scss']
})

export class NavLightComponent implements
  OnInit, AfterViewInit {
    constructor(private router : Router) { }
    activeManu:string = '';

    ngOnInit(): void {
        this.activeManu = this.router.url;
        window.scrollTo(0, 0);
    }
    ngAfterViewInit() {
    }


  
    status: boolean = false;
    toggleMenu(){
        this.status = !this.status;       
    }
    manu:boolean = true;


    manuOpen:string=''
    subManu(item:any){
      this.manu = !this.manu;  
      this.manuOpen = item
    }
  
    windowScroll() {
      const navbar = document.getElementById('topnav') as HTMLInputElement;
      if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
        navbar.classList.add('nav-sticky');
      } else {
        navbar.classList.remove('nav-sticky');
      }
  
      var mybutton = document.getElementById("back-to-top");
      if (mybutton != null) {
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
          mybutton.classList.add("block");
          mybutton.classList.remove("hidden");
        } else {
          mybutton.classList.add("hidden");
          mybutton.classList.remove("block");
        }
      }
  
    }
  

}
