import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavLightComponent } from '../../components/navbar/nav-light/nav-light.component';
import { FooterOneComponent } from '../../components/footer/footer-one/footer-one.component';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [CommonModule,NavLightComponent,FooterOneComponent,RouterLink],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {

}
