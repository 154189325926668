import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FooterOneComponent } from 'app/components/footer/footer-one/footer-one.component';
import { NavdarkComponent } from 'app/components/navbar/navdark/navdark.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule,NavdarkComponent,FooterOneComponent,RouterLink],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  aboutData = [
    {
      icon:'uil uil-phone',
      title:'Phone',
      desc2: '',
      desc:'The phrasal sequence of the is now so that many campaign and benefit',
      link:'tel:+34 986 344 000',
      name:'+34 986 344 000'
    },
    {
      icon:'uil uil-envelope',
      title:'Email',
      desc2: '',
      desc:'The phrasal sequence of the is now so that many campaign and benefit',
      link:'contacto@aimen.es',
      name:'contacto@aimen.es'
    },
    {
      icon:'uil uil-map-marker',
      title:'Location',
      desc2: 'O Porriño (Pontevedra)',
      desc:'Polígono Industrial de Cataboi SUR-PPI-2 (Sector 2) Parcela 3, 36418 ',
      link:'tel:+34 986 344 000',
      name:'View on Google map'
    },
  ]
}
