<nav id="topnav" class="defaultscroll is-sticky" (window:scroll)="windowScroll()">
    <div class="container relative">
        <!-- Logo container-->
        <a class="logo" routerLink="/">
            <span class="inline-block dark:hidden">
                <img src="assets/images/logos/AimenshellEngLogBlanco.png" class="inline-block dark:hidden" alt="">
                <img src="assets/images/logos/AimenshellEngLogBlanco.png" class="hidden dark:inline-block" alt="">
            </span>
            <img src="assets/images/logos/AimenshellEngLogBlanco.png" class="hidden dark:inline-block" alt="">
        </a>

        <!-- End Logo container-->
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->
        <ul class="buy-button list-none mb-0">
            <li class="inline ps-1 mb-0">
                <a href="https://www.aimen-shell-toolkit.com" target="_blank" class="py-2 px-5 font-semibold inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white rounded-md me-2">Application</a>
            </li>
        </ul>
        <!--Login button End-->

        <div id="navigation" [style]="status  ? 'display: block;' : 'display: none;'">
            <!-- Navigation Menu-->   
            <ul class="navigation-menu nav-light">
                <li [ngClass]="activeManu === '/' ? 'active' : ''"><a routerLink="/" class="sub-menu-item">Home</a></li>
        
                <li><a routerLink="/contact-one" class="sub-menu-item">Features</a></li>
                <li><a routerLink="/contact-one" class="sub-menu-item">FAQ</a></li>

                <li><a routerLink="/contact-one" class="sub-menu-item">About Us</a></li>
                <li [ngClass]="activeManu === '/contact-one' ? 'active' : ''"><a routerLink="/contact-one" class="sub-menu-item">Contact</a></li>
            </ul><!--end navigation menu-->
        </div><!--end navigation-->
    </div><!--end container-->
</nav>

