import { Injectable } from '@angular/core';
import { ApiProvider } from 'providers/api/api-provider';
import { environment as env } from "../environments/environment";
import { Connection } from 'models/connection';

@Injectable({
  providedIn: 'root'
})
export class ToolkitApiService {
  toolkit:Connection;

  constructor(
    private api: ApiProvider,

  ) {
    this.toolkit=env.toolkitApi;

   }


  getTotalDigitalTwin(){
    return this.api.get(this.toolkit.host, 'api/total');
  }
}
